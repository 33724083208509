<template>
    <div>
        <div class="col-sm-12">
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('landingPage.settingContact.titleBlock') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    {{ dataForm.block_contact?.title_block ?? '' }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.background') }}</label>
                <div class="col-sm-9">
                    <div v-if="dataForm.block_contact.background_image?.path" style="width: 200px; height: 200px" class="position-relative">
                        <b-img
                            class=""
                            thumbnail
                            fluid
                            :src="dataForm.block_contact.background_image?.path"
                            :alt="dataForm.block_contact.background_image.origin_name"
                            style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                        ></b-img>
                    </div>
                    <div class="my-3">
                        <InputText
                            style="max-width: 100px"
                            :model.sync="dataForm.block_contact.background_color"
                            :config="{
                                type: 'color',
                                classCustom: 'form-control-color',
                                error: false
                            }"
                            :disabled="true"
                        />
                    </div>
                    <div>
                        <InputCheckBox
                            :model.sync="dataForm.block_contact.is_repeat"
                            :id="`check-repeat-background-contact`"
                            :value="dataForm.block_contact.is_repeat"
                            :label="`repeat`"
                            :disabled="true"
                        />
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.style') }} </label>
                <div class="col-sm-9">
                    {{ dataForm.block_contact.style?.name ?? '' }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.contactOfficeName') }}</label>
                <div class="col-sm-9">
                    {{ dataForm.block_contact?.contact_secretariat_name ?? '' }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.tel') }} </label>
                <div class="col-sm-9">
                    {{ dataForm.block_contact?.tel ?? '' }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.mail') }}</label>
                <div class="col-sm-9">
                    {{ dataForm.block_contact?.mail ?? '' }}
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingContact.detail') }} </label>
                <div class="col-sm-9">
                    <div class="body__preview" v-html="dataForm.block_contact?.html_content ?? ''"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { InputText, InputCheckBox } from '@/components/Input';

export default {
    name: 'SettingContact',
    components: {
        InputText,
        InputCheckBox
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {};
    },
    methods: {},
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
