<template>
    <div>
        <Layout>
            <div v-if="!isLoading" class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-body">
                        <b-tabs
                            v-model="modelTab"
                            vertical
                            justified
                            nav-class="nav-tabs-custom tabs_landing--page disabled"
                            content-class="p-3 text-muted scroll--content__landingpage"
                        >
                            <b-tab v-for="(tab, index) in TABS" :key="index" class="border-0" :disabled="tab.disabled">
                                <template v-slot:title>
                                    <span class="">{{ tab.name }}</span>
                                </template>
                                <div>
                                    <SettingBasicLP v-if="tab.id == 1" :dataForm="formData" />
                                    <SettingDesign v-else-if="tab.id == 2" :dataForm="formData" />
                                    <SettingBlocks v-else-if="tab.id == 3" :dataForm="formData" />
                                    <SettingFirstView v-else-if="tab.id == 4" :dataForm="formData" />
                                    <SettingTimeApplication v-else-if="tab.id == 5" :dataForm="formData" />
                                    <SettingPrizeList v-else-if="tab.id == 6" :dataForm="formData" />
                                    <SettingTargetProduct v-else-if="tab.id == 7" :dataForm="formData" />
                                    <SettingApplicationMethod v-else-if="tab.id == 8" :dataForm="formData" />
                                    <SettingReceiptPhotography v-else-if="tab.id == 9" :dataForm="formData" />
                                    <SettingApplicationRule v-else-if="tab.id == 10" :dataForm="formData" />
                                    <SettingContact v-else-if="tab.id == 11" :dataForm="formData" />
                                    <SettingShare v-else-if="tab.id == 12" :dataForm="formData" />
                                    <!-- <SettingButton v-else-if="tab.id == 13" :dataForm="formData" /> -->
                                    <!-- <SettingFreeBlocks v-else-if="tab.id == 14" :dataForm="formData" /> -->
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <Footer>
                    <div class="d-flex justify-content-center">
                        <div class="text-center flex-fill">
                            <button type="submit" @click="handleCancel" class="btn btn-light mr-3">{{ $t('btn.cancel') }}</button>
                            <button type="submit" @click="handleGoTo" class="btn btn-primary">{{ $t('btn.edit') }}</button>
                        </div>
                        <div v-if="$route.query.id">
                            <button type="button" class="btn btn-danger ml-3" @click="handleDeleteLP({ id: $route.query.id })">
                                {{ $t('btn.delete') }}
                            </button>
                        </div>
                    </div>
                </Footer>
            </div>
            <div class="d-flex justify-content-center" v-else>
                <LoadingIcon />
            </div>
        </Layout>
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import SettingBasicLP from './components/setting-basic/main.vue';
import SettingFirstView from './components/setting-first_view/main.vue';
import SettingPrizeList from './components/setting-prize/main.vue';
import SettingTargetProduct from './components/setting-target_product/main.vue';
import SettingDesign from './components/setting-design/main.vue';
import SettingBlocks from './components/setting-blocks/main.vue';
import SettingTimeApplication from './components/setting-time_application/main.vue';
import SettingApplicationMethod from './components/setting-application_method/main.vue';
import SettingReceiptPhotography from './components/setting-receipt_photography/main.vue';
import SettingApplicationRule from './components/setting-application_rule/main.vue';
import SettingShare from './components/setting-share/main.vue';
import SettingContact from './components/setting-contact/main.vue';
// import SettingFreeBlocks from './components/setting-free_blocks/main.vue';
// import SettingButton from './components/setting-button/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Swal from 'sweetalert2';

import { TABS, initFormRegister } from '../constants';
import { masterMethods, landingPageMethods } from '@/state/helpers';
import { handleDataResponse, handleRecordLPError } from '../handlers';
import { showMessage } from '@/utils/messages';

export default {
    name: 'ViewLandingPage',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        LoadingIcon,
        SettingBasicLP,
        SettingFirstView,
        SettingPrizeList,
        SettingTargetProduct,
        SettingDesign,
        SettingBlocks,
        SettingTimeApplication,
        SettingApplicationMethod,
        SettingReceiptPhotography,
        SettingApplicationRule,
        SettingShare,
        SettingContact
        // SettingButton,
        // SettingFreeBlocks
    },
    data() {
        return {
            TABS: TABS(),
            formData: initFormRegister(),
            modelTab: 0,
            isLoading: false
        };
    },
    methods: {
        ...masterMethods,
        ...landingPageMethods,

        handleCancel() {
            this.$router.push({
                path: '/landing-page/list'
            });
        },

        handleGoTo() {
            this.$router.push({
                path: '/landing-page/edit',
                query: {
                    id: this.$route.query.id
                }
            });
        },

        async handleDeleteLP(payload) {
            Swal.fire({
                title: 'このLPを削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                cancelButtonText: 'キャンセル',
                confirmButtonText: 'OK'
            }).then(async (result) => {
                if (result.value) {
                    try {
                        const response = await this.deleteLandingPage(payload);
                        if (response.code !== 200) return;
                        this.$router.push({ path: '/landing-page/list' }).then(() => {
                            showMessage(response.code, this.$bvToast, 'LPが削除されました。');
                        });
                    } catch (error) {
                        console.log('error', error);
                    }
                }
            });
        },

        async getDetail() {
            if (!this.$route?.query?.id) return;
            this.isLoading = true;
            try {
                const response = await this.getDetailLandingPage(this.$route.query.id);
                if (!response?.campaign?.id) {
                    handleRecordLPError(this.$router, this.$route.query.id, this.$bvToast);
                    return;
                }
                handleDataResponse(this.formData, response, []);
                console.log('response', response);
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoading = false;
            }
        }
    },
    mounted() {
        this.getDetail();
    }
};
</script>

<style lang="scss"></style>
