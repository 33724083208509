<template>
    <div>
        <div class="col-sm-12">
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.titleLPforPrize') }}</label>
                <div class="col-sm-9">
                    {{ dataForm.prize_settings.title }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.background') }}</label>
                <div class="col-sm-9">
                    <div>
                        <div v-if="dataForm.prize_settings.background.image?.path" style="width: 200px; height: 200px" class="position-relative">
                            <b-img
                                class=""
                                thumbnail
                                fluid
                                :src="dataForm.prize_settings.background.image?.path"
                                :alt="dataForm.prize_settings.background.image.origin_name"
                                style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                            ></b-img>
                        </div>
                    </div>
                    <div class="my-3">
                        <InputText
                            style="max-width: 100px"
                            :model.sync="dataForm.prize_settings.background.color"
                            :config="{
                                type: 'color',
                                classCustom: 'form-control-color',
                                error: false
                            }"
                            :disabled="true"
                        />
                    </div>
                    <div>
                        <InputCheckBox
                            :model.sync="dataForm.prize_settings.is_repeat"
                            :id="`check-repeat-background-prize_lp`"
                            :value="dataForm.prize_settings.is_repeat"
                            :label="`repeat`"
                            :disabled="true"
                        />
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingPrize.selectedStyle') }} </label>
                <div class="col-sm-9">
                    <div>
                        {{ dataForm.prize_settings.style?.name }}
                    </div>
                    <div class="mt-3 body__preview" v-html="dataForm.prize_settings.html_content"></div>
                </div>
            </div>
            <!-- <div v-for="(evt, k) in dataForm.prize_settings.prizes" :key="`prize-${k}`" class="card p-2">
                <div class="card-body position-relative border-bottom">
                    <div class="d-flex flex-row mt-3">
                        <div class="mr-3">
                            <div class="d-flex align-items-center justify-content-center h-100">
                                <InputCheckBox class="custom-switch" :model.sync="evt.status" :id="`prize-${k}`" :value="true" :label="``" />
                            </div>
                        </div>
                        <div class="mr-3 col-sm-1" style="min-width: 120px">
                            <img
                                loading="lazy"
                                :src="evt?.image?.path"
                                :alt="evt?.image?.origin_name"
                                class="img-fluid mx-auto d-block rounded h-100 w-100"
                                style="object-fit: contain"
                            />
                        </div>
                        <div>
                            <div class="col-sm-12 p-0">
                                <h4 v-b-tooltip.hover :title="evt.title">{{ truncateTextTable(evt.title, 50) ?? '' }}</h4>
                            </div>
                            <div class="col-sm-12 p-0">
                                <a target="_blank" :href="evt.image?.path">{{ truncateTextTable(evt.image?.path, 300) ?? '' }} </a>
                            </div>
                            <div class="p-1" v-html="truncateTextTable(evt.detail, 500) ?? ''"></div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { InputCheckBox, InputText } from '@/components/Input';
// import { truncateText } from '@/utils/format';

export default {
    name: 'SettingPrizeList',
    components: {
        InputCheckBox,
        InputText
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {};
    },

    methods: {
        // truncateTextTable(text, length) {
        //     return truncateText(text, length);
        // }
    },
    watch: {},
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
