<template>
    <div>
        <div class="col-lg-12">
            <ExportLandingPage :listBlockLP="dataForm.blocks_settings" />
            <!-- <div class="d-flex">
                <div class="col-sm-5">
                    <div v-for="(blockItem, idx) in dataForm.blocks_settings" :key="idx" class="mb-3">
                        <div class="row">
                            <div class="col-sm-5">
                                <h5 v-b-tooltip.hover :title="blockItem.name" class="col-sm-12 text-truncate">{{ blockItem.name }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7">
                    <ExportLandingPage :listBlockLP="dataForm.blocks_settings" />
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import ExportLandingPage from '../export-page/main.vue';

export default {
    name: 'SettingBlockLP',
    components: { ExportLandingPage },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {};
    },
    methods: {}
};
</script>

<style lang="scss"></style>
