<template>
    <div>
        <div class="col-sm-12">
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('landingPage.settingApplicationMethod.titleBlock') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    {{ dataForm.block_application_method.title_block }}
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationMethod.background') }}</label>
                <div class="col-sm-9">
                    <div
                        v-if="dataForm.block_application_method.background_image?.path"
                        style="width: 200px; height: 200px"
                        class="position-relative"
                    >
                        <b-img
                            class=""
                            thumbnail
                            fluid
                            :src="dataForm.block_application_method.background_image?.path"
                            :alt="dataForm.block_application_method.background_image.origin_name"
                            style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                        ></b-img>
                    </div>
                    <div class="my-3">
                        <InputText
                            style="max-width: 100px"
                            :model.sync="dataForm.block_application_method.background_color"
                            :config="{
                                type: 'color',
                                classCustom: 'form-control-color',
                                error: false
                            }"
                            :disabled="true"
                        />
                    </div>
                    <div>
                        <InputCheckBox
                            :model.sync="dataForm.block_application_method.is_repeat"
                            :id="`check-repeat-background-app_method`"
                            :value="dataForm.block_application_method.is_repeat"
                            :label="`repeat`"
                            :disabled="true"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingApplicationMethod.style') }} </label>
                <div class="col-sm-9">
                    {{ dataForm.block_application_method.style?.name }}
                    <div class="mt-3 body__preview" v-html="dataForm.block_application_method.html_content"></div>
                </div>
            </div>

            <!-- <div v-for="(itemAppMethod, k) in dataForm.block_application_method.application_methods" :key="`appMethods-${k}`" class="card p-2">
                <div class="card-body position-relative border-bottom">
                    <div class="d-flex flex-row mt-3">
                        <div class="mr-3">
                            <div class="d-flex align-items-center justify-content-center h-100">
                                <InputCheckBox
                                    class="custom-switch"
                                    :model.sync="itemAppMethod.status"
                                    :id="`appMethods-${k}`"
                                    :value="true"
                                    :label="``"
                                    @change="handleShotCodeStyle"
                                />
                            </div>
                        </div>
                        <div class="mr-3 col-sm-1" style="min-width: 120px">
                            <img
                                loading="lazy"
                                :src="itemAppMethod?.image?.path"
                                :alt="itemAppMethod?.image?.origin_name"
                                class="img-fluid mx-auto d-block rounded h-100 w-100"
                                style="object-fit: contain"
                            />
                        </div>
                        <div>
                            <div class="col-sm-12 p-0">
                                <h4 v-b-tooltip.hover :title="itemAppMethod.title">
                                    {{ truncateTextTable(itemAppMethod.title, 50) ?? '' }}
                                </h4>
                            </div>
                            <div class="col-sm-12 p-0">
                                <a target="_blank" :href="itemAppMethod.image?.path"
                                    >{{ truncateTextTable(itemAppMethod.image?.path, 300) ?? '' }}
                                </a>
                            </div>
                            <div class="p-1" v-html="truncateTextTable(itemAppMethod.detail, 500) ?? ''"></div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { truncateText } from '@/utils/format';
import { InputText, InputCheckBox } from '@/components/Input';

export default {
    name: 'SettingApplicationMethod',
    components: {
        InputText,
        InputCheckBox
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {};
    },

    methods: {
        truncateTextTable(text, length) {
            return truncateText(text, length);
        }
    },
    watch: {},
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
