<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.nameCampaign') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm?.campaign?.name ?? '' }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.detailFirstView') }} </label>
                    <div class="col-sm-9">
                        <div class="body__preview" v-html="dataForm.first_view.html_content"></div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { renderLineBreaks } from '@/utils/format';

export default {
    name: 'SettingFirstViewAndTimeApplication',
    components: {},
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {};
    },
    methods: {
        textLineBreaks(text) {
            return renderLineBreaks(text);
        }
    }
};
</script>

<style lang="scss" scoped></style>
