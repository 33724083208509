<template>
    <div>
        <div class="col-sm-12">
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingReceiptPhotography.title') }} </label>
                <div class="col-sm-9">
                    <div class="body__preview" v-html="dataForm.block_receipt_photography_tutorial?.html_content ?? ''"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingReceiptPhotography',
    components: {},
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {};
    },
    methods: {},
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
