<template>
    <div class="body__preview">
        <!-- {{ arrBlocksLP }} -->
        <div v-for="(itemLP, idx) in arrBlocksLP" :key="idx">
            <div v-if="itemLP.key !== 'block_free'" class="mb-2" v-html="itemLP.body_html"></div>
            <textarea v-else v-model="itemLP.content_free" rows="8" class="block_free" :id="`${idx}-free`" disabled></textarea>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExportLandingPage',
    props: {
        listBlockLP: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        arrBlocksLP() {
            return this.$props.listBlockLP?.filter((oBlock) => oBlock.is_visibled);
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped></style>
