<template>
    <div>
        <div class="col-sm-12">
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                    >{{ $t('landingPage.settingShare.titleBlock') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    {{ dataForm.block_share?.title_block ?? '' }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingShare.background') }}</label>
                <div class="col-sm-9">
                    <div>
                        <div v-if="dataForm.block_share.background_image?.path" style="width: 200px; height: 200px" class="position-relative">
                            <b-img
                                class=""
                                thumbnail
                                fluid
                                :src="dataForm.block_share.background_image?.path"
                                :alt="dataForm.block_share.background_image.origin_name"
                                style="width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 10px"
                            ></b-img>
                        </div>
                    </div>
                    <div class="my-3">
                        <InputText
                            style="max-width: 100px"
                            :model.sync="dataForm.block_share.background_color"
                            :config="{
                                type: 'color',
                                classCustom: 'form-control-color',
                                error: false
                            }"
                            :disabled="true"
                        />
                    </div>
                    <div>
                        <InputCheckBox
                            :model.sync="dataForm.block_share.is_repeat"
                            :id="`check-repeat-background-share`"
                            :value="dataForm.block_share.is_repeat"
                            :label="`repeat`"
                            :disabled="true"
                        />
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingShare.url') }} </label>
                <div class="col-sm-9">
                    {{ dataForm.block_share?.url ?? '' }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingShare.shareText') }}</label>
                <div class="col-sm-9">
                    {{ dataForm.block_share?.share_text ?? '' }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingShare.hashtag') }} </label>
                <div class="col-sm-9">
                    {{ onListHashtag(dataForm.block_share?.hashtag) }}
                </div>
            </div>
            <div v-for="social in SOCIAL_MEDIA_SHARE" :key="social.id" class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ social.name }}</label>
                <div class="col-sm-9">
                    <InputCheckBox
                        :model.sync="dataForm.block_share.platform[social.key]"
                        :id="`check-social-${social.id}`"
                        :value="dataForm.block_share.platform[social.key]"
                        :label="``"
                        :disabled="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { InputText, InputCheckBox } from '@/components/Input';
import { SOCIAL_MEDIA_SHARE } from '../../../constants';

export default {
    name: 'SettingShare',
    components: {
        InputText,
        InputCheckBox
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },

    data() {
        return {
            SOCIAL_MEDIA_SHARE: SOCIAL_MEDIA_SHARE
        };
    },
    methods: {
        onListHashtag(arr) {
            if (!arr || arr?.length <= 0) return '';
            return arr.join('、');
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
