<script>
import { clientMethods, checkPermission, masterMethods, checkPermissionName, formattedPostalCode } from '@/state/helpers';
import { renderLineBreaks } from '@/utils/format';
import { campaignStatus } from '@/config/var-common';
import { OPTIONS_TYPE } from '@/components/Campaign/TabClients/constants';
import { showMessage } from '@/utils/messages';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Swal from 'sweetalert2';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

export default {
    page: {
        title: 'エンドクライアント詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        Footer
    },
    data() {
        return {
            loading: false,
            id: this.$route.query.id,
            form: null,
            listClientCP: [],
            listAgencySupports: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            arrRoleClient: ['client.view', 'client.edit', 'client.delete']
        };
    },
    mounted() {
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
            this.getListAgencySupport(this.$route.query.id);
        }
    },
    methods: {
        formattedPostalCode,
        checkPermission,
        ...masterMethods,
        ...clientMethods,
        getDetail(id) {
            this.loading = true;
            this.detailClient(id).then((data) => {
                this.form = data;
                this.loading = false;
                this.listClientCP = data.campaigns;
            });
        },
        textLineBreaks(text) {
            if (!text) return '';
            return renderLineBreaks(text);
        },
        renderSttText(numberStt) {
            const result = campaignStatus.find((item) => item.id === numberStt);
            return result ? result.text : '';
        },
        goToEdit(path, id, client_id) {
            this.$router.push({
                path: path,
                query: { id: id, client_id: client_id }
            });
        },
        getListAgencySupport(id) {
            this.listClientSupport(id).then((data) => {
                this.listAgencySupports = data.data;
            });
        },
        formatDateToChinese(time) {
            if (!time) return '';
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
            const date = new Date(time);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            return `${year} 年 ${month} 月 ${day} 日`;
        },
        deleteObject(data) {
            if (this.listClientCP.length) {
                Swal.fire({
                    title: '紐付けられているキャンペーンがあるため削除できません。',
                    icon: 'warning',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#b5b8bf',
                    cancelButtonText: 'キャンセル'
                });
            } else {
                Swal.fire({
                    title: 'このエンドクライアントを削除してもよろしいですか。',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#5664d2',
                    cancelButtonColor: '#b5b8bf',
                    confirmButtonText: 'OK',
                    cancelButtonText: 'キャンセル'
                }).then((result) => {
                    if (result.value) {
                        this.deleteClient(data).then((data) => {
                            if (data.code == 200) {
                                this.$router.push({ path: '/client/list' }).then(() => {
                                    showMessage(data.code, this.$bvToast, 'エンドクライアントが削除されました。');
                                });
                            }
                        });
                    }
                });
            }
        },
        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },

        renderType(type) {
            if (!type) return '';
            let index = OPTIONS_TYPE.findIndex((x) => x.id == type);
            if (index >= 0) return OPTIONS_TYPE[index].name;
        },

        handleJoinName(params) {
            if (params && params.length <= 0) return '';
            return params.map((jo) => jo.value).join('、');
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation">
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">登録年月日</label>
                                        <div class="col-sm-9">
                                            {{ formatDateToChinese(form?.created_at) }}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">会社名</label>
                                        <div class="col-sm-9">
                                            {{ form?.name }}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.kana_name') }}</label>
                                        <div class="col-sm-9">
                                            {{ form?.kana_name }}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">法人番号</label>
                                        <div class="col-sm-9">
                                            {{ form?.corp_number }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">電話番号</label>
                                        <div class="col-sm-9">
                                            {{ form?.phone_number }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">住所</label>
                                        <div class="col-sm-9">
                                            〒{{ formattedPostalCode(form?.postal_code) }}
                                            {{ form?.province }}
                                            {{ form?.address_1 }}
                                            {{ form?.address_2 }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">会社概要</label>
                                        <div class="col-sm-9">
                                            <p v-html="textLineBreaks(form?.profile)"></p>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">URL</label>
                                        <div class="col-sm-9">
                                            <a :href="form?.url" target="_blank">{{ form?.url }}</a>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.keyword') }}</label>
                                        <div class="col-sm-9">
                                            <div v-if="form?.keywords">
                                                <span v-for="(i, k) in form.keywords" :key="'p' + k">{{ k > 0 ? ', ' : '' }}{{ i }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">顧客種別</label>
                                        <div class="col-sm-9">
                                            {{ renderType(form?.type_id) }}
                                        </div>
                                    </div>

                                    <div v-if="form?.type_id" class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ renderType(form?.type_id) }}</label>
                                        <div class="col-sm-9">
                                            {{ handleJoinName(form?.option_type) }}
                                        </div>
                                    </div>

                                    <hr />
                                    <h5><b>キャンペーン一覧</b></h5>

                                    <div class="table-responsive">
                                        <table class="table mb-0 table-bordered table-box">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>CP名</th>
                                                    <th>進捗ステータス</th>
                                                    <th>事務局開始日</th>
                                                    <th>CP終了日</th>
                                                    <th>事務局終了日</th>
                                                    <th>担当</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="listClientCP.length > 0">
                                                <template>
                                                    <tr v-for="(item, k) in listClientCP" :key="'c' + k">
                                                        <td>
                                                            {{ k + 1 }}
                                                        </td>

                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.name }}<br />
                                                                {{ item.name_kana ? item.name_kana : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ renderSttText(item.progress_status) }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.secretariat_start_date ? item.secretariat_start_date : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.campaign_end_date ? item.campaign_end_date : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.secretariat_end_date ? item.secretariat_end_date : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.person_in_charge ? item.person_in_charge.name : '' }}
                                                            </div>
                                                        </td>

                                                        <td align="center">
                                                            <div class="row-data-small">
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    class="text-info"
                                                                    @click="goToEdit('/campaign/form/view', item.id)"
                                                                    >詳細</a
                                                                >
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                            <tbody v-else>
                                                <template>
                                                    <tr style="position: relative; height: 80px">
                                                        <td
                                                            style="
                                                                position: absolute;
                                                                left: 50%;
                                                                top: 50%;
                                                                transform: translate(-50%, -50%);
                                                                border: none;
                                                            "
                                                        >
                                                            <p class="mt-3 text-center">
                                                                {{ $t('notificationCommon.noData') }}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center mt-4 pb-4">
                                        <button
                                            @click="
                                                $router.push({
                                                    path: '/campaign/form'
                                                })
                                            "
                                            type="button"
                                            class="btn btn-primary"
                                        >
                                            CP登録
                                        </button>
                                    </div>
                                    <br />

                                    <h5><b>担当者一覧</b></h5>
                                    <div class="table-responsive">
                                        <table class="table mb-0 table-bordered table-box">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('clientSupport.name') }}</th>
                                                    <th>{{ $t('clientSupport.kana_name') }}</th>
                                                    <th>{{ $t('clientSupport.department') }}</th>
                                                    <th>{{ $t('clientSupport.email') }}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="listAgencySupports.length > 0">
                                                <template>
                                                    <tr v-for="item in listAgencySupports" :key="'s' + item.id">
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.name }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.kana_name ? item.kana_name : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.department ? item.department : '' }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="row-data-small">
                                                                {{ item.email ? item.email : '' }}
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <div class="row-data-small">
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    class="text-info border-right-1"
                                                                    @click="goToEdit('/client/form/support/view', item.id, $route.query.id)"
                                                                    >詳細</a
                                                                >
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    class="text-success"
                                                                    @click="goToEdit('/client/form/support', item.id, $route.query.id)"
                                                                    >編集</a
                                                                >
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                            <tbody v-else>
                                                <template>
                                                    <tr style="position: relative; height: 80px">
                                                        <td
                                                            style="
                                                                position: absolute;
                                                                left: 50%;
                                                                top: 50%;
                                                                transform: translate(-50%, -50%);
                                                                border: none;
                                                            "
                                                        >
                                                            <p class="mt-3 text-center">
                                                                {{ $t('notificationCommon.noData') }}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center mt-4 pb-4">
                                        <button @click="goToEdit('/client/form/support', '', $route.query.id)" type="button" class="btn btn-primary">
                                            担当者登録
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/client/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermission(arrRoleClient[1])"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/client/form', $route.query.id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermission(arrRoleClient[2])"
                            type="btn"
                            class="btn btn-danger ml-3"
                            @click="deleteObject({ id: $route.query.id })"
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
