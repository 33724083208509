<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.applicationPeriod') }}</label>
                    <div class="col-sm-9">
                        {{ dataForm.application_period.title }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.startDate') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm.application_period.start_date }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.endDate') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm.application_period.end_date }}
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFirstView.selectedStyle') }} </label>
                    <div class="col-sm-9">
                        <div>
                            {{ dataForm.application_period.style?.name }}
                        </div>
                        <div class="mt-3 body__preview" v-html="dataForm.application_period.html_content"></div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingTimeApplication',
    components: {},
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {};
    },
    methods: {},
    mounted() {}
};
</script>

<style lang="scss" scoped></style>
