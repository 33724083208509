<template>
    <div>
        <div class="col-sm-12">
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.fontSize') }} </label>
                <div class="col-sm-4">
                    {{ dataForm.font_page?.name }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.squareType') }} </label>
                <div class="col-sm-9">
                    {{ dataForm.square_type }}
                    <!-- listSquareType -->
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.backgroundColor') }} </label>
                <div class="col-sm-1">
                    {{ dataForm.background_color }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.titleColor') }} </label>
                <div class="col-sm-1">
                    {{ dataForm.title_color }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.fontColor') }} </label>
                <div class="col-sm-1">
                    {{ dataForm.font_color }}
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-2 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingDesgin.buttonColor') }} </label>
                <div class="col-sm-1">
                    {{ dataForm.button_color }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { LIST_FONT_PAGE, SQUARE_TYPE_PAGE } from '../../../constants';

export default {
    name: 'SettingDesginLP',
    components: {},
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            listFontFamily: LIST_FONT_PAGE,
            listSquareType: SQUARE_TYPE_PAGE
        };
    }
};
</script>

<style lang="scss" scoped></style>
